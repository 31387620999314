/* eslint-disable no-console */
<template>
  <v-container fill-height>
    <v-row justify="center" align="start">
      <v-col>
        <h2 class="text-h3 text-center black--text">My Art</h2>
      </v-col>
    </v-row>
    <v-row justify="center" align="start">
      <v-col>
        <h4
          class="text-subtitle-1 text-center black--text"
        >Miscellaneous art projects from my past, of miscellaneous quality.</h4>
      </v-col>
    </v-row>
    <v-row justify="center" align="start">
      <v-spacer></v-spacer>
    </v-row>
    <v-row justify="center" align="start">
      <v-expansion-panels accordion :multiple="multiple" v-model="panel">
        <v-expansion-panel v-for="(project, i) in projects" :key="i">
          <v-expansion-panel-header expand-icon v-ripple="{ 'class' : 'primary--text'}">
            <v-row>
              <v-col :class="justifyCheck(i)">
                <span class="text-h4">{{ project.title }}&nbsp;</span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="cols-12">
                <h4 class="text-h6">{{ project.date }}</h4>
                <span class="text-body-1">{{ project.description }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-lg-6 col-sm-12" v-for="(image, j) in project.images" :key="j">
                <v-img :src="getImgUrl(image.url)" :alt="image.alt" width="100%"></v-img>
                <span class="text-subtitle-1">{{ image.description }}</span>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row justify="center" align="start">
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Art",
  data() {
    return {
      getImgUrl: function(url) {
        return require("../assets/" + url);
      },
      justifyCheck: function(i) {
        // Switch alignment of the panel header text.
        if (i % 2 === 0) {
          return "text-left";
        }
        return "text-right";
      },
      multiple: false,
      projects: [
        {
          title: "The Lair of the Beard",
          images: [
            {
              url: "lair-onfire1.jpeg",
              alt: "Image of The Lair of the Beard on fire.",
              description:
                "On the final night of Flipside, we burn the effigy in a massive ceremony attended by nearly everyone at the event. Why do we burn it? Because it's a lot easier than taking it apart and hauling it away."
            },
            {
              url: "lair-blueprint.png",
              alt: "Screenshot of early computer model for project.",
              description:
                "I modeled this project in Google Sketchup. It's not an ideal tool for building detailed blueprints, but it was enough for an amateur."
            },
            {
              url: "lair-warehouse-work.jpeg",
              alt:
                "Picture of the DaFT members building and laying out box trusses.",
              description:
                "The effigy was built at the (former) Flipside waarehouse in East Austin. Two nights a week and all day Sunday the Design and Fabrication Team (DaFT) would meet to build. The DaFT team consisted of two dozen volunteers, with all levels of experience in art and construction."
            },
            {
              url: "lair-truss-arch.jpeg",
              alt:
                "Picture of a partially-constructed arch set up vertically at the Flipside warehouse.",
              description: "...there were arch things..."
            },
            {
              url: "lair-throne-base.jpeg",
              alt: "Picture of the wooden throne in its inital assembly.",
              description: "...we built a throne..."
            },
            {
              url: "lair-throne-primed.jpeg",
              alt:
                "Picture of the wooden throne after receiving a layer of paint.",
              description:
                "We primed the base of the throne to allow it to be internally lit."
            },
            {
              url: "lair-roof-construction.jpeg",
              alt:
                "Picture of the slats that were used to build a roof for the Lair.",
              description:
                "Having sufficient shade is a major concern at Flipside because of the Texas heat. The lair needed some form of roof. However, since the structure was to be set on fire we had to avoid using material like plywood, as laminated wood buring that high in the air posed a real risk of sending out large embers into surrounding areas and setting them on fire. To compromise between safety and cost we went with spaced slats"
            }
          ],
          date: "Spring, 2014",
          description: "The Lair of the Beard was the effigy for Flipside 2014."
        },
        {
          title: "More to Come",
          images: [
            {
              url: "comingSoon.png",
              alt: "Coming Soon.",
              description: ""
            }
          ],
          date: "Summer, 2020",
          description:
            "This page was initally going to be a super-brief overview of a few things, but as I'm going on I really enjoy documenting some of my previous projects. So there will be more here, but it will also take more time to build."
        }
      ],
      panel: 0
    };
  },
  mounted() {}
};
</script>

<style></style>
